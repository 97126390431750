.authors-container {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.authors-container h1 {
  text-align: center;
  font-size: 2.5em;
  color: #256472;
  margin-bottom: 20px;
}

.authors-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.author-card {
  text-align: center;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: box-shadow 0.3s ease;
  background-color: #f9f9f9;
}

.author-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.author-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.author-card h2 {
  font-size: 1.5em;
  color: #ff5722;
  margin-bottom: 10px;
}

.author-card p {
  font-size: 0.9em;
  color: #555;
  margin-bottom: 15px;
}

.view-posts-link {
  display: inline-block;
  padding: 8px 12px;
  font-size: 0.9em;
  color: #ffffff;
  background-color: #256472;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.view-posts-link:hover {
  background-color: #052d36;
}
