/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Noto Sans Kannada", sans-serif;
}

/* Header Container */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #256472;
  padding: 10px 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Logo */
.logo img {
  height: 50px;
}

/* Navbar Styles */
.navbar {
  display: flex;
  gap: 20px;
  align-items: center;
  position: relative;
  transition: transform 0.3s ease-in-out;
}

.navbar a {
  color: #ffdd00;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  transition: color 0.3s ease;
}

.navbar a:hover {
  color: #ffc107;
}

/* Hamburger Menu for Small Screens */
.hamburger {
  display: none;
  font-size: 2rem;
  background: none;
  border: none;
  color: #131312;
  cursor: pointer;
}

.hamburger:focus {
  outline: none;
}

/* Mobile Navigation */
.navbar.open {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  z-index: 1500; /* Ensure menu overlays other elements */
}

.navbar.open a {
  font-size: 1.5rem;
  color: #ffffff;
}

/* Media Queries */
@media (max-width: 768px) {
  .hamburger {
    display: block; /* Show hamburger menu on small screens */
  }

  .navbar {
    display: none; /* Hide navbar initially */
  }

  .navbar.open {
    display: flex; /* Show navbar when menu is open */
  }
}

/* Layout wrapper */
.layout-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  padding-bottom: 25px; /* Match new footer height */
}

/* Main content - Add padding to prevent content from going under header */
.main-content {
  flex: 1;
  padding: 20px;
  margin-top: 70px; /* Height of the header */
  min-height: calc(100vh - 130px); /* Viewport height minus header and footer */
}

/* Footer - Make it sticky */
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #256472;
  color: #fff;
  padding: 3px 0; /* Even smaller padding */
  z-index: 1000;
  height: 25px; /* Reduced height further */
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

/* Make footer text smaller */
.footer-section {
  margin: 0;
  font-size: 0.65rem; /* Even smaller font */
}

.footer-section p {
  margin: 0;
  display: inline-block;
  margin-right: 8px; /* Smaller spacing */
  line-height: 1; /* Tighter line height */
}

/* Adjust footer links */
.footer-links {
  display: flex;
  gap: 8px; /* Smaller gap */
  font-size: 0.65rem; /* Match smaller text size */
}

.footer-links a {
  color: #fff;
  text-decoration: none;
}

/* Cookie banner - Adjust z-index to appear above footer */
.cookie-banner {
  position: fixed;
  bottom: 25px; /* Match new footer height */
  left: 0;
  right: 0;
  background-color: #f8f9fa;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001; /* Higher than footer */
}

.cookie-banner button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Adjust mobile view */
@media (max-width: 768px) {
  .footer {
    height: auto;
    padding: 5px 0;
    min-height: 25px;
  }

  .footer-content {
    gap: 3px;
  }
  
  .footer-section {
    font-size: 0.4rem; /* Even smaller on mobile */
  }

  .footer-section p {
    margin: 1px 0;
  }

  .footer-links {
    font-size: 0.6rem;
    margin-top: 2px;
  }

  .main-content {
    margin-bottom: 60px; /* Adjusted for smaller footer */
  }
}

/* Add hover effect for footer links */
.footer-links a:hover {
  color: #ffdd00;
  text-decoration: none;
}

/* Style footer email link */
.footer-section a {
  color: #ffdd00;
  text-decoration: none;
}

.footer-section a:hover {
  text-decoration: underline;
}
