/* Keyframes for animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateX(20px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

/* Container for Author Details */
.authors-container {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.5s ease-out;
  will-change: transform;
}

/* Author Header Section */
.author-header {
  text-align: center;
  margin-bottom: 30px;
}

.author-header img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid #256472;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  transition: transform 0.3s ease;
  will-change: transform;
}

.author-header img:hover {
  transform: scale(1.05);
}

.author-header h1 {
  font-size: 2em;
  color: #256472;
  margin-bottom: 10px;
  font-weight: bold;
}

.author-header p {
  font-size: 1.1em;
  color: #666;
  line-height: 1.6;
}

/* Ankana Thumbnail */
.ankana-link {
  display: inline-block;
  margin-top: 1rem;
}

.ankana-thumbnailauthor {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.ankana-thumbnailauthor:hover {
  transform: scale(1.05);
}

/* Author Posts Section */
.author-posts {
  margin-top: 40px;
}

.author-posts h2 {
  font-size: 1.8em;
  color: #256472;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
}

/* Grid Layout for Posts */
.posts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

/* Individual Post Cards */
.post-card-author {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  border: 1px solid #ddd;
  animation: slideIn 0.5s ease-out;
  will-change: transform;
}

.post-card-author:hover {
  transform: translateY(-5px);
}

.post-card-author h3 {
  font-size: 1.5em;
  color: #256472;
  margin-bottom: 10px;
}

.post-card-author a {
  text-decoration: none;
  color: #ffdd00;
  font-weight: bold;
}

.post-card-author a:hover {
  color: #e6c800;
}

.post-card-author p {
  font-size: 1em;
  color: #666;
  line-height: 1.6;
  margin-top: 10px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.pagination button {
  background-color: #256472;
  color: #ffdd00;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  will-change: transform;
}

.pagination button:not(:disabled) {
  animation: pulse 2s infinite;
}

.pagination button:not(:disabled):hover {
  background-color: #073944;
  animation: none;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Responsive Design */
@media (max-width: 768px) {
  .posts-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .author-header img {
    width: 100px;
    height: 100px;
  }

  .author-header h1 {
    font-size: 1.5em;
  }

  .author-header p {
    font-size: 1em;
  }
  
  .ankana-thumbnail {
    max-width: 200px; /* Adjust for smaller screens */
  }
}