.privacy-policy {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.privacy-policy h1 {
  color: #333;
  margin-bottom: 30px;
}

.privacy-policy section {
  margin-bottom: 30px;
}

.privacy-policy h2 {
  color: #444;
  margin-bottom: 15px;
}

.privacy-policy p {
  line-height: 1.6;
  color: #666;
} 