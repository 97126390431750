.ankana-carousel-container {
  margin: 20px auto;
  width: 90%;
  max-width: 1200px;
}

.ankana-slide {
  text-align: center;
}

.ankana-slide img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
