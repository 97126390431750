/* Container Styling */
.ankana-scroll-container {
  margin: 20px auto;
  max-width: 1400px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ankana-scroll-container h3 {
  font-size: 1.5em;
  color: #003366;
  margin-bottom: 20px;
  text-align: center;
}

/* Buttons Container */
.ankana-scroll-buttons {
  display: flex;
  align-items: center;
  position: relative;
}

/* Left and Right Buttons */
.scroll-button {
  background-color: #003366;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  padding: 12px;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll-button.left {
  left: 10px;
}

.scroll-button.right {
  right: 10px;
}

.scroll-button i {
  font-size: 20px; /* Icon size */
}

.scroll-button:hover {
  background-color: #0056b3;
}

/* Scrollable Wrapper */
.ankana-scroll-wrapper {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  padding: 20px 0;
  scroll-behavior: smooth;
  flex: 2;
  -webkit-overflow-scrolling: touch;
}

/* Individual Item */
.ankana-item {
  flex: 0 0 auto; /* Allow items to take natural width */
  text-align: center;
  width: 200px; /* Set a fixed width for each item */
}

.ankana-thumbnail {
  width: 100%; /* Image will fill its container */
  height: auto;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.ankana-item a {
  text-decoration: none;
  color: inherit;
}

/* Scroll Buttons - Disabled State */
.scroll-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  opacity: 0.5;
}

/* Inside your existing CSS file */
.larger-thumbnail {
  width: 100%; /* Make the image take full width of its container */
  height: auto; /* Maintain aspect ratio */
  max-width: 300px; /* Adjust this to your desired maximum width */
}

/* Adjust .ankana-item to accommodate larger thumbnails */
.ankana-item {
  flex: 0 0 auto; /* Allow items to take natural width */
  text-align: center;
  width: 300px; /* Match the max-width of the thumbnail or adjust as needed */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .ankana-item {
    width: 200px; /* Smaller width for mobile */
  }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .ankana-item {
    width: 150px; /* Smaller width for mobile */
  }

  .scroll-button {
    padding: 10px;
    font-size: 12px;
  }
}