.share-buttons {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin: 20px 0;
  }
  
  .share-button {
    display: inline-block;
    text-decoration: none;
    border-radius: 50%;
    overflow: hidden;
    width: 50px;
    height: 50px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .share-logo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .share-button:hover .share-logo {
    transform: scale(1.1);
  }
  
   