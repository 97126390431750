.ankanas-background {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f0f0;
  padding: 10px;
}

.ankanas-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 1400px;
}

.ankana-image {
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
  transition: transform 0.2s ease, box-shadow 0.3s ease;
}

.ankana-image:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.ankana-thumbnail {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1.5rem;
}

.pagination-button {
  background-color: #256472;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.pagination-button:hover {
  background-color: #02242c;
  transform: translateY(-2px);
}

.pagination-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
.ankanas-heading {
  color: #256472; /* Example color, adjust as needed */
  text-align: center;
  font-size: 2rem; /* Adjust size as needed */
  margin-bottom: 20px; /* Space between heading and images */
}

.pagination-controls span {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}

.loading-message,
.error-message {
  text-align: center;
  font-size: 1.5rem;
  color: #777;
  margin-top: 20px;
}

/* Media query for desktop */
@media (min-width: 1024px) {
  .ankanas-grid {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr)); /* Further increase for larger screens */
  }
}

/* Media query for mobile screens */
@media (max-width: 768px) {
  .ankanas-background {
    padding: 0;
  }

  .ankanas-grid {
    grid-template-columns: 1fr;
    gap: 0;
    max-width: 100%;
  }

  .ankana-image {
    width: 100%;
    border-radius: 0;
    border-bottom: 1px solid #e0e0e0;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    transition: all 0.3s ease;
  }

  .ankana-image:active {
    background-color: #f5f5f5;
    transform: scale(0.98);
  }

  .ankana-thumbnail {
    width: 95%;
    height: auto;
    display: block;
    object-fit: cover;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .ankana-thumbnail:active {
    transform: scale(0.98);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}