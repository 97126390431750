.comment-section-container {
    margin: 20px 0;
    padding: 15px;
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .comment-list {
    margin-top: 10px;
  }
  
  .comment-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    padding: 10px;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .comment-avatar {
    margin-right: 15px;
  }
  
  .avatar-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .comment-details {
    flex: 1;
  }
  
  .comment-author {
    font-weight: bold;
    font-size: 1.1em;
    margin-bottom: 5px;
    color: #003366;
  }
  
  .comment-text {
    font-size: 1em;
    margin-bottom: 5px;
    color: #333;
  }
  
  .comment-timestamp {
    font-size: 0.9em;
    color: #888;
  }
  
  .load-more-button {
    display: block;
    margin: 10px auto 0;
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .load-more-button:hover {
    background-color: #0056b3;
  }
  
  .error-message {
    color: red;
    font-weight: bold;
    margin-top: 10px;
  }
  
  .no-comments {
    font-size: 1.1em;
    color: #555;
    margin-top: 10px;
    text-align: center;
  }
  