/* Post Details Container */
.post-details-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Title */
.post-details-container h1 {
  font-size: 2em;
  margin-bottom: 10px;
  color: #256472;
  text-align: center;
}

/* Author Section */
.post-details-container .author-name {
  font-size: 1em;
  color: rgb(3, 75, 75);
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}
.post-date {
  text-align: center;
  margin-top: 5px;
  font-size: 0.9rem;
  color: #555;
}

/* Share Buttons Section */
.share-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.share-button-logo {
  width: 40px;
  height: 40px;
  transition: transform 0.3s ease;
}

.share-button-logo:hover {
  transform: scale(1.1);
}

/* Main Content */
.post-content {
  font-size: 1.1em;
  line-height: 1.8;
  color: #333;
  text-align: justify;
  margin-top: 20px;
}

.post-content p {
  margin-bottom: 20px;
}

/* Images in Content */
.inline-post-image {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 20px auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Highlighted Text */
.highlight-text {
  display: inline;
  background-color: #ffeb3b; /* Yellow background */
  font-weight: bold;
  padding: 2px 4px;
  border-radius: 3px;
}
/* Image at the top of the post */
.post-top-image {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 20px auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Inline Images in Content */
.inline-post-image {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 20px auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.comments-section h2 {
  text-decoration-color: #256472;
}