/* Background container similar to the home page */
.about-us-background,
.contact-us-background {
    background-color: #f9f9f9;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

/* Main content container */
.about-us-container,
.contact-us-container {
    background: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    max-width: 800px;
    width: 100%;
    padding: 20px;
    animation: fadeIn 1s ease-in-out;
}

/* Inner content for paragraphs */
.content-container {
    margin-top: 20px;
    font-size: 1.2em;
    line-height: 1.8;
    color: #333;
    text-align: justify;
}

/* Title styling */
.about-us-container h1,
.contact-us-container h1 {
    text-align: center;
    color: #055164;
    margin-bottom: 20px;
    font-size: 2.5em;
}

/* Fade-in animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fade-in {
    animation: fadeIn 1s ease-in-out;
}
