/* Global Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Noto Sans Kannada", sans-serif;
  background-color: #f9f9f9;
}

/* Header Section */
.header {
  background-color: #256472;
  padding: 0.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: url("../../public/assets/header-bg.png");
  background-size: cover;
  background-repeat: repeat;
}

.header .logo img {
  height: 50px;
  margin-left: 15px;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #256472;
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.navbar a {
  color: #ffdd00;
  text-decoration: none;
  font-weight: bold;
  margin: 0 10px;
  font-size: 1rem;
  transition: color 0.3s;
}

.navbar a:hover {
  color: #ffc107;
}

/* Home Page Layout */
.home-container {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.section-title {
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #256472;
  text-align: center;
}

/* Posts Section */
/* Container for posts */
.posts {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap into multiple rows */
  gap: 20px; /* Adds spacing between items */
  justify-content: space-between; /* Distributes items evenly with spacing */
  margin-top: 20px;
}

/* Post cards */
.post-card {
  flex: 1 1 calc(33.33% - 20px); /* Adjusts the width dynamically */
  max-width: calc(33.33% - 20px); /* Ensures a consistent maximum width */
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  margin: 10px 0; /* Adds vertical spacing */
}

/* Image in post cards */
.post-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

/* Title and text */
.post-card h2 {
  font-size: 1.5em;
  margin: 10px 15px;
}

.post-card p {
  margin: 10px 15px;
  color: #666;
}

.post-card a {
  color: #ff5722;
  font-weight: bold;
  text-decoration: none;
}

.post-card a:hover {
  color: #e64a19;
}

.post-card:hover {
  transform: scale(1.05); /* Adds a hover effect */
}

.post-author {
  margin-top: 10px;
  font-size: 0.9em;
  color: #666;
}

.post-author a {
  text-decoration: none;
  color: #256472;
  font-weight: bold;
}

.post-author a:hover {
  text-decoration: underline;
}

/* Carousel Section */
.article-carousel-wrapper {
  position: relative;
  margin-bottom: 20px;
}

.article-carousel {
  display: flex;
  gap: 20px;
  overflow-x: scroll;
  padding: 10px;
  scroll-behavior: smooth;
}

.carousel-item {
  min-width: 300px;
  max-width: 400px;
  flex: 0 0 auto;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.carousel-item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.carousel-item a {
  display: block;
  padding: 10px;
  color: #256472;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
}

.carousel-item a:hover {
  text-decoration: underline;
}
/* Pagination Styling */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}

.pagination button {
  background-color: #256472;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  outline: none;
}

.pagination button:hover {
  background-color: #063641;
  transform: scale(1.1);
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination span {
  font-size: 14px;
  color: #333;
}

/* Responsive Design */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .article-carousel {
    overflow-x: auto;
    padding: 0;
  }

  .carousel-item {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .post-card {
    flex: 1 1 calc(50% - 20px); /* Two cards per row */
    max-width: calc(50% - 20px);
  }
}

/* For Mobile Phones (max-width: 480px, one card per row) */
@media (max-width: 480px) {
  .post-card {
    flex: 1 1 100%; /* One card per row */
    max-width: 100%;
  }
}
@media (max-width: 480px) {
  .post-card h2 {
    font-size: 1rem;
  }

  .post-card p {
    font-size: 0.9rem;
  }
}

/* Footer Section */
footer {
  background-color: #256472;
  color: #ffdd00;
  padding: 1rem;
  text-align: center;
  margin-top: 20px;
}

footer p {
  margin: 0;
  font-size: 1rem;
}
