.google-login-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4285f4;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .google-login-button img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  
  .google-login-button:hover {
    background-color: #357ae8;
  }
  
  .logged-in-container {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  .user-info {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  .logout-button {
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 15px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .logout-button:hover {
    background-color: #d32f2f;
  }
  