.comment-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
    width: 100%;
    padding: 15px;
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color .3ms ease;
  }
  
  .user-info {
    margin-bottom: 10px;
    font-size: 1rem;
    color: #333;
    text-align: center;
  }
  
  .user-info span {
    font-weight: bold;
    color: #256472;
  }
  
  .logout-button {
    margin-left: 10px;
    padding: 5px 10px;
    font-size: 0.9rem;
    color: #ffffff;
    background-color: #ff5252;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .logout-button:hover {
    background-color: #ff0000;
  }
  
  textarea {
    width: 90%;
    max-width: 600px;
    height: 80px;
    margin-bottom: 10px;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: none;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  textarea:focus {
    border-color: #256472;
    box-shadow: 0 0 5px rgba(0, 86, 179, 0.2);
  }
  
  button[type="submit"] {
    padding: 10px 20px;
    font-size: 1rem;
    color: #ffffff;
    background-color: #256472;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button[type="submit"]:hover {
    background-color: #04333e;
  }
  
  .error-message {
    color: #ff5252;
    font-size: 0.9rem;
    margin-top: -10px;
    margin-bottom: 10px;
    text-align: center;
  }
  