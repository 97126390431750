.ankana-details-container {
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ankana-details-container h1{
  font-size: 1.8em;
  color: #256472;
  margin-bottom: 10px;
  text-align: center;
}
.ankana-banner {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 20px;
}

.latest-post {
  margin-bottom: 40px;
}

.latest-post h1 {
  font-size: 1.8em;
  color: #256472;
  margin-bottom: 10px;
}

.other-posts {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Adjust minimum and maximum card widths */
  gap: 20px; /* Spacing between cards */
  margin-top: 20px;
}

.poster-card{
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  word-wrap: break-word; /* Ensure text doesn't overflow */
  min-height: 250px; /* Ensure a minimum height for each card */
  width: 100%; /* Ensure cards take the full width */
}

.poster-card h3 {
  font-size: 1.1em;
  color: #256472;
  margin-bottom: 10px;
}

.poster-card a {
  text-decoration: none;
  color: #ff5722;
  font-weight: bold;
}

.poster-card a:hover {
  text-decoration: underline;
}

.poster-card p {
  font-size: 0.9em;
  line-height: 1.6;
  color: #555;
  margin-top: 5px;
}


.load-more-button {
  display: inline-block;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  background-color: #256472;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.load-more-button:hover {
  background-color: #06353f;
  transform: scale(1.05);
}

.load-more-button:active {
  transform: scale(1);
}

.load-more-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  box-shadow: none;
}

.highlight-text {
  background-color: #ffefc1;
  padding: 2px 5px;
  border-radius: 3px;
  font-style: italic;
  color: #333;
}

.h1 {
  font-size: 1.1em;
  color: #256472;
  margin-bottom: 10px;
}